import {
  Row,
  Col,
  Button,
  message,
  Form,
  Input,
  MenuProps,
  Select, Checkbox,
} from "antd";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addNewUser, getAdminGroups, getCities, getDarkStores } from "store/admin-slice/adminServicesSlice";
import { fetchWarehouses } from "store/stock-exchange/stockExchangeSlice";

const MESSAGE_KEY = "CREATE_ADMIN_USER";
export const AddNewUserPage = () => {
  const [selectedCity, setSelectedCity] = useState<number | null>(null);
  useDocumentTitle("Add New User");
  const { status, error, adminUserGroups, darkStores, adminUsers, cities } = useAppSelector(
    (state) => state.adminServices
  );
  const { warehouses } = useAppSelector(
    (state) => state.stockExchangeServices
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  // Filter stores based on selected city
  const filteredStores = darkStores.filter(store => 
    selectedCity ? store.cityId === selectedCity : true
  );

  const handleCityChange = (value: number) => {
    setSelectedCity(value);
    // Clear mapped stores when city changes
    form.setFieldsValue({ mappedStores: [] });
  };

  const adminGroupsCheckboxOptions = adminUserGroups
    .filter((items => items.name != 'administrator' && items.name != 'internal'))
    .map(items => ({
      value: items.id,
      label: items.name,
    }));

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, []);

  useEffect(() => {
    dispatch(getAdminGroups());
    dispatch(getCities());
    dispatch(getDarkStores());
    return () => {
      message.destroy();
    };
  }, []);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Admin Users Added!",
        key: MESSAGE_KEY,
      });
      form.resetFields();
      navigate(-1);
    } else if (status === "saving") {
      message.loading({
        content: "Saving User",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  const [form] = Form.useForm();
  const onSave = async () => {
    try {
      await form.validateFields();
      await dispatch(addNewUser(form.getFieldsValue()));
    }
    catch (e) {
      //Place logger here
    }
  };

  const onCancelSave = () => {
    navigate(-1);
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="Add New User" />
        </Col>
      </Row>
      <Form
        form={form}
        name="vendor-filter-form"
        layout="vertical"
        style={{ width: "50%" }}
      >
        <Form.Item name="firstname" label="First Name" rules={[
          {
            required: true,
          },
        ]}>
          <Input placeholder="First Name" size="small" allowClear required />
        </Form.Item>
        <Form.Item name="lastname" label="Last Name" rules={[
          {
            required: true,
          },
        ]}>
          <Input placeholder="Last Name" size="small" allowClear required />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[
          {
            required: true,
            type: "email",
            message: "Please enter a valid email",
          },
        ]}>
          <Input placeholder="Email" size="small" allowClear required />
        </Form.Item>
        <Form.Item name="phone" label="Phone" rules={[
          {
            pattern: new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
            message: "Invalid phone number",
            min: 10,
            max: 10,
            required: true,
          },
        ]} >
          <Input
            placeholder="Phone" 
            size="middle" 
            maxLength={10} 
            allowClear 
          />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          required
        >
          <Select
            defaultValue="MALE"
            options={[
              {
                value: 'MALE',
                label: 'Male',
              }, {
                value: 'FEMALE',
                label: 'Female',
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Mapped Warehouses"
          name="mappedWh"
        >
          <Select
            mode="multiple"
            options={warehouses.map(wareHouse => ({
              value: wareHouse.id,
              label: `${wareHouse.name} # ${wareHouse.id}`,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="City"
        >
          <Select
            value={selectedCity}
            onChange={handleCityChange}
            options={cities.map(city => ({
              value: city.id,
              label: `${city.name}, ${city.state}`,
            }))}
            allowClear
            placeholder="Select city to filter stores"
          />
        </Form.Item>

        <Form.Item
          label="Mapped Stores"
          name="mappedStores"
        >
          <Select
            mode="multiple"
            options={filteredStores.map(store => ({
              label: `${store.id} || ${store.name}`,
              value: store.id,
            }))}
          />
        </Form.Item>
        
        <Form.Item name="groups" label="Groups">
          <Checkbox.Group options={adminGroupsCheckboxOptions} />
        </Form.Item>
      </Form>
      <Row>
        <Col>
          <Button
            type="primary"
            onClick={onSave}
            loading={status === "loading"}
            disabled={status === "loading"}
          >
            Add User
          </Button>
        </Col>
        <Col style={{ justifyContent: "left", marginLeft: "20px" }}>
          <Button
            onClick={onCancelSave}
            disabled={status === "loading"}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};