import axios, {AxiosInstance} from "axios";
import {getPersistedUser} from "services/PersistService/PersistUserService";
import * as process from "process";
import { getWarehouseId } from "services/PersistService/PersistWareHouseReturn";

export const AUTH_HEADER_NAME = "rz-auth-key";
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const apis = {
    login: `${API_DOMAIN}/api/v1/admins/auth/password/login`,
    uploadRequisition: `${API_DOMAIN}/api/v1/stores/requisitions/upload`,
    uploadRequisitionUserMapping: `${API_DOMAIN}/api/v1/stores/requisitions/upload/user-picking-mapping`,
    saveRequisitionUserMapping: `${API_DOMAIN}/api/v1/stores/requisitions/upload/save/user-picking-mapping`,
    saveStoreSecPicking: `${API_DOMAIN}/api/v1/stores/secondary-picking/upload/save`,
    uploadStoreSecPicking: `${API_DOMAIN}/api/v1/stores/secondary-picking/upload`,
    uploadPickerMapping: `${API_DOMAIN}/api/v1/ppd/picker-packer/upload`,
    savePickerMapping: `${API_DOMAIN}/api/v1/ppd/picker-packer/upload/save`,  
    uploadProjectedSr: `${API_DOMAIN}/api/v1/procurements/projected-sr/upload`,
    saveProjectedSr: `${API_DOMAIN}/api/v1/procurements/projected-sr/upload/save`,
    cronRemapUsers: `${API_DOMAIN}/api/v1/ppd/cron/remap-users`,
    uploadStoreRejectionMapping: `${API_DOMAIN}/api/v1/inventory/stores/rejected/upload`,
    saveStoreRejection: `${API_DOMAIN}/api/v1/inventory/stores/rejected/upload/save`,
    saveRequisition: `${API_DOMAIN}/api/v1/stores/requisitions/upload/save`,
    dispatchRequisition: `${API_DOMAIN}/api/v1/stores/requisitions/dispatch`,
    getRequisitions: `${API_DOMAIN}/api/v1/stores/requisitions/non-dispatched`,
    downloadSecondaryPicking: `${API_DOMAIN}/api/v1/stores/secondary-picking/jit/csv-download`,
    getSuggestedPicking: `${API_DOMAIN}/api/v1/stores/requisitions/suggestion/picking`,
    getDispatchedRequisitions: `${API_DOMAIN}/api/v1/stores/requisitions/non-dispatched`,
    getRequisitionsById: `${API_DOMAIN}/api/v1/stores/requisitions`,
    createChallan: `${API_DOMAIN}/api/v1/stores/challan/requisitions`,
    uploadNaPickingUserMapping: `${API_DOMAIN}/api/v1/stores/requisitions/upload/user-na-picking-mapping`,
    saveNaPickingUserMapping: `${API_DOMAIN}/api/v1/stores/requisitions/upload/save/user-na-picking-mapping`,
    uploadFaPickingUserMapping: `${API_DOMAIN}/api/v1/stores/requisitions/fa/picker/upload`,
    saveFaPickingUserMapping: `${API_DOMAIN}/api/v1/stores/requisitions/fa/picker/upload/save`,
    uploadMiddleMileMapping: `${API_DOMAIN}/api/v1/middle-mile/delivery/route/upload`,
    saveMiddleMileMapping: `${API_DOMAIN}/api/v1/middle-mile/delivery/route/upload/save`,
    saveMiddleMileRemark: `${API_DOMAIN}/api/v1/middle-mile/delivery`,
    getMiddleMilesListing: `${API_DOMAIN}/api/v1/middle-mile/route/app`,
    getMiddleMilesTrackingInfo: `${API_DOMAIN}/api/v1/middle-mile/delivery`,
    slots: `${API_DOMAIN}/api/v1/stores/requisitions/slots`,
    vendors: `${API_DOMAIN}/api/v1/vendors`,
    uploadVendorImage: `${API_DOMAIN}/api/v1/vendors/image/upload`,
    updateVendorStatus: `${API_DOMAIN}/api/v1/vendors/image/upload`,
    uploadInvoiceFile: `${API_DOMAIN}/api/v1/purchaseOrders/file/upload`,
    pos: `${API_DOMAIN}/api/v1/purchaseOrders`,
    uploadPO: `${API_DOMAIN}/api/v1/purchaseOrders/upload`,
    uploadBillingRate: `${API_DOMAIN}/api/v1/purchaseOrders/upload/billingRate`,
    uploadSavePO: `${API_DOMAIN}/api/v1/purchaseOrders/upload/save`,
    uploadSaveBillingRate: `${API_DOMAIN}/api/v1/purchaseOrders/upload/billingRate/save`,
    masterSkus: `${API_DOMAIN}/api/v1/masterSkus`,
    assets: `${API_DOMAIN}/api/v1/assets`,
    addNewAdmin: `${API_DOMAIN}/api/v1/admins`,
    addNewSociety: `${API_DOMAIN}/api/v1/ppd/society`,
    getAdminGroups: `${API_DOMAIN}/api/v1/groups`,
    getAdminUsers: `${API_DOMAIN}/api/v1/admins/listing`,
    getAdminUser: `${API_DOMAIN}/api/v1/admins`,
    getStoresInfo: `${API_DOMAIN}/api/v1/stores`,
    storeReturn: `${API_DOMAIN}/api/v1/storeReturns`,
    allStoreReturnList: `${API_DOMAIN}/api/v1/storeReturns`,
    qaOptions: `${API_DOMAIN}/api/v1/storeReturns/qa-result`,
    fullSrReturnOptions: `${API_DOMAIN}/api/v1/storeReturns/full-sr-ticket-categories`,
    createFullSrReturn: `${API_DOMAIN}/api/v1/storeReturns/create/full-sr`,
    saveStoreReturnDraft: `${API_DOMAIN}/api/v1/storeReturns/saveDraft`,
    saveStoreReturnMapping: `${API_DOMAIN}/api/v1/storeReturns/upload/save`,
    uploadStoreReturnMapping: `${API_DOMAIN}/api/v1/storeReturns/upload`,
    uploadFranchiseMapping: `${API_DOMAIN}/api/v1/franchise-stores/inventory/upload`,
    saveFranchiseMapping: `${API_DOMAIN}/api/v1/franchise-stores/inventory/upload/save`,
    franchiseCatalogue: `${API_DOMAIN}/api/v1/catalog/wh/`,
    cancelPpdOrder: `${API_DOMAIN}/api/v1/ppd/order/`,
    ppdOrders: `${API_DOMAIN}/api/v1/ppd/orders`,
    ppdOrder: `${API_DOMAIN}/api/v1/ppd/order`,
    ppdDeliveryOrders: `${API_DOMAIN}/api/v1/ppd/delivery/orders`,
    ppdDeliveryOrder: `${API_DOMAIN}/api/v1/ppd/delivery/order`,
    warehouseListing: `${API_DOMAIN}/api/v1/ppd/delivery/order`,
    getVendorSupplyLocations: `${API_DOMAIN}/api/v1/vendors/locations`,

    // PPD Tables
    getPpdTableInfo: `${API_DOMAIN}/api/v1/ppd/store/configs/web`,
    savePpdTableInfo: `${API_DOMAIN}/api/v1/ppd/store/configs`,

    /// Stock Tranfer Services
    inventoryTypes: `${API_DOMAIN}/api/v1/stockTransfer/inventory-type`,
    warehouses: `${API_DOMAIN}/api/v1/warehouses`,
    uploadStockTransfer: `${API_DOMAIN}/api/v1/stockTransfer/upload`,
    saveStockTransfer: `${API_DOMAIN}/api/v1/stockTransfer/upload/save`,
    uploadHoldAdjustment: `${API_DOMAIN}/api/v1/inventory/warehouse/hold/adjust/bulk-upload`,
    saveHoldAdjustment: `${API_DOMAIN}/api/v1/inventory/warehouse/hold/adjust/bulk-upload/confirm`,
    getStockTransfers: `${API_DOMAIN}/api/v1/stockTransfer`,
    uploadInternelStockTransfer: `${API_DOMAIN}/api/v1/stockTransfer/internal/upload`,
    saveInternelStockTransfer: `${API_DOMAIN}/api/v1/stockTransfer/internal/upload/save`,
    uploadUserDayMapping: `${API_DOMAIN}/api/v1/mappings/user-day/upload`,
    saveUserDayMapping: `${API_DOMAIN}/api/v1/mappings/user-day/upload/save`,
    uploadSocietySequenceMapping: `${API_DOMAIN}/api/v1/ppd/society/sequencing/upload`,
    saveSocietySequenceMapping: `${API_DOMAIN}/api/v1/ppd/society/sequencing/upload/save`,
    getQualityChecks: `${API_DOMAIN}/api/v1/qualityChecks`,
    receiveCashCollection: `${API_DOMAIN}/api/v1/cash-collections/receive`,
    getCashCollections: `${API_DOMAIN}/api/v1/cash-collections`,
    savePricingOverride: `${API_DOMAIN}/api/v1/franchise-stores/pricing-override/upload/save`,
    uploadPricingOverride: `${API_DOMAIN}/api/v1/franchise-stores/pricing-override/upload`,
    fetchCategories: `${API_DOMAIN}/api/v1/franchise-stores/inventory-prices/categories`,
    uploadCategoryPricing: `${API_DOMAIN}/api/v1/franchise-stores/inventory-prices/upload`,
    saveCategoryPricing: `${API_DOMAIN}/api/v1/franchise-stores/inventory-prices/upload/save`,
    // addWhSku: `${API_DOMAIN}/api/v1/catalog/wh-sku`,
    getStoreReturns: `${API_DOMAIN}/api/v1/stores/requisitions`,
    getStoreReturnRequestInfo: `${API_DOMAIN}/api/v1/storeReturns/sr-id`,
    uploadStoreReturnImages: `${API_DOMAIN}/api/v1/storeReturns/upload/files`,
    toggleBulkStatus: `${API_DOMAIN}/api/v1/stores/requisitions`,
    fetchLatestPricesStatus: `${API_DOMAIN}/api/v1/catalog/price-flag`,
    updateLatestPricesStatus: `${API_DOMAIN}/api/v1/catalog/price-flag`,
    getClustersList: `${API_DOMAIN}/api/v1/delivery/clusters`,
    getClusterRoutes: `${API_DOMAIN}/api/v1/middle-mile/cluster/`,
    mergeClusterRoutes: `${API_DOMAIN}/api/v1/middle-mile/routes/merge`, 
    uploadClusterStoreMapping: `${API_DOMAIN}/api/v1/cluster/stores/upload`,
    saveClusterStoreMapping: `${API_DOMAIN}/api/v1/cluster/stores/upload/save`,
    uploadClusterAmMapping: `${API_DOMAIN}/api/v1/cluster/fa/upload`,
    saveClusterAmMapping: `${API_DOMAIN}/api/v1/cluster/fa/upload/save`,
    getDarkStore: `${API_DOMAIN}/api/v1/stores?storeType=DARK`,
    getRightAwayStore: `${API_DOMAIN}/api/v1/stores?storeType=RIGHT_AWAY`,
    getClusters: `${API_DOMAIN}/api/v1/ppd/clusters`,
    uploadConsumerClusterSheet: `${API_DOMAIN}/api/v1/ppd/cluster/upload`,
    saveConsumerClusterSheet: `${API_DOMAIN}/api/v1/ppd/cluster/upload/save`,
    uploadConsumerClusterSocietySheet: `${API_DOMAIN}/api/v1/ppd/cluster/society/upload`,
    saveConsumerClusterSocietySheet: `${API_DOMAIN}/api/v1/ppd/cluster/society/upload/save`,
    saveVendorWarehouseMapping: `${API_DOMAIN}/api/v1/wh-sku/preset-vendor/upload/save`,
    uploadVendorWarehouseMapping: `${API_DOMAIN}/api/v1/wh-sku/preset-vendor/upload`,

    getDelayRequests: `${API_DOMAIN}/api/v1/ppd/delivery/delay`,
    updateDelayRequests: `${API_DOMAIN}/api/v1/ppd/delivery/delay`,
    getPpdSlots: `${API_DOMAIN}/api/v1/ppd/order/slots`,
    
    getSplitSku: `${API_DOMAIN}/api/v1/combo-sku`,
    createSplitSku: `${API_DOMAIN}/api/v1/combo-sku`,
    deleteSplitSku: `${API_DOMAIN}/api/v1/combo-sku`,
   
    getCitiesList: `${API_DOMAIN}/api/v1/city`,
}

let axiosInstance: AxiosInstance;

const createAxiosInstance = () => {
    axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(config => {
        const token = getPersistedUser().authToken;
        let whId = getWarehouseId();
        config.headers = {
            appId: "wms-portal",
        };
        
        if (token) {
            config.headers = {
                ...config.headers,
                [AUTH_HEADER_NAME]: token,
            }
            if (whId) {
                config.headers = { ...config.headers, "wh-id": whId,  }
            }
        }
        return config;
    });
}

const _API = () => {
    if (!axiosInstance) {
        createAxiosInstance();
    }

    return axiosInstance;
};

export default _API();