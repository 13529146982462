import { Row, Col, message, Table, Pagination, Button, Popconfirm, Select, Avatar, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { AppPpdDeliveryOrderFilter } from "../../components/AppFilter/AppPpdDeliveryOrderFilter/AppPpdDeliveryOrderFilter";
import { IPpdDeliveryOrders } from "../../common/interface/IPpdDeliveryOrders";
import { getPpdDeliveryOrders } from "store/ppd/ppdSlice";
import { fallBackImage } from "../../common/styles";
import { apis } from "../../common/apis";
import { routes } from "common/routes";
import * as process from "process";
import { getCities, getDarkStores } from "store/admin-slice/adminServicesSlice";

type PartialPpdDeliveryOrderType = Partial<IPpdDeliveryOrders> & { key: string };
const MESSAGE_KEY = "all_ppd_delivery_orders";
const headerKeys: { [key: string]: string } = {
    ppdOrderId: "Ppd OrderId",

    displayOrderId: "Display OrderId",

    deliveryDate: "Delivery Date",

    route: "Route / PPS",

    address: "Delivery Address",

    cityId: "City",

    packerUser: "Packer Info",

    packerImages: "Packer Images",

    userInfo: "Delivery User",

    images: "Delivery Images",

    status: "Status",

    actions: "Actions",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeliveryOrdersPage = () => {
    useDocumentTitle("PPD Delivery Orders");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialPpdDeliveryOrderType>>();
    const [rows, setRows] = useState<PartialPpdDeliveryOrderType[]>();
    const { ppdDeliveryOrders, status, error } = useAppSelector((state) => state.ppd);
    const { cities } = useAppSelector((state) => state.adminServices);
    const [filterState, setFilterState] = useState(false);
    const [searchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);
    const navigate = useNavigate();

    const getPpdDeliveryOrdersData = useCallback(
        (pageNum = 1, pageSize = 25, filter = "") => {
            dispatch(getPpdDeliveryOrders({ pageNo: pageNum, pageSize, filter }));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(getDarkStores());
        dispatch(getCities());
    }, []);

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getPpdDeliveryOrdersData(1, 25, filter);
    }, [dispatch, getPpdDeliveryOrdersData, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading PPD Orders", key: MESSAGE_KEY });
        } else if (status === "success") {
            // message.success({
            //   content: "Admin Users loaded!",
            //   key: MESSAGE_KEY,
            // });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        const ppdDeliveryOrdersData = ppdDeliveryOrders?.data ?? [];

        const data: PartialPpdDeliveryOrderType[] = (ppdDeliveryOrders?.data ?? []).map((au, i) => {
            const {
                ppdOrderId,
                cityId,
                ppdOrder,
                deliveryDate,
                deliveryUserName,
                deliveryUserPhone,
                userId,
                images,
                status,
                metadata,
            } = au;

            const newRow = {
                ppdOrderId,
                cityId,
                ppdOrder,
                deliveryDate,
                deliveryUserName,
                deliveryUserPhone,
                userId,
                images,
                status,
                metadata,
            };
            return { ...newRow, key: `ppdDeliveryOrders-${i}`, ellipses: true };
        });

        const tableHeaders: ColumnsType<PartialPpdDeliveryOrderType> = [
            ...Object.keys(headerKeys).map((key) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                width: getHeaderWeight(key),
                render: (text: string, record: PartialPpdDeliveryOrderType) => {
                    if (key === "deliveryDate") {
                        return (
                            <>
                                {record.deliveryDate?.split("T")[0]}
                            </>
                        )
                    }

                    if (key === "cityId") {
                        return <div>{cities.find(e => e.id === record.cityId)?.name}, {cities.find(e => e.id === record.cityId)?.state}</div>;
                      }
                    
                    if (key === "userId") {
                        return (
                            <>
                                <>{`(${record.userId}) ${record.deliveryUserName}`}</>
                                <>{record.deliveryUserPhone}</>
                            </>
                        )
                    }
                    if (key === "displayOrderId") {
                        return (
                            <>
                                {record.ppdOrder?.metadata?.displayOrderId}
                            </>
                        )
                    }

                    if (key === "route") {
                        return (
                            <>
                                {record.ppdOrder?.batchId} / {record.ppdOrder?.sequenceNo}
                            </>
                        )
                    }

                    if (key === "address") {
                        return (
                            <>
                                {`${record.ppdOrder?.metadata.address.addressLine1}, ${record.ppdOrder?.metadata.address.addressLine2}, ${record.ppdOrder?.metadata.address.landmark}, ${record.ppdOrder?.metadata.address.city}, ${record.ppdOrder?.metadata.address.state}`}
                            </>
                        )
                    }

                    if (key === "packerUser") {
                        //TODO: Update these.
                        return (
                            <Col>
                                <Row>Name: <>{record.ppdOrder?.packerDetails?.name}</></Row>
                                <Row>Phone: <>{record.ppdOrder?.packerDetails?.phone}</></Row>
                            </Col>
                        );
                    }

                    if (key === "packerImages") {
                        //TODO: Update these.
                        return (
                            <Row align="middle" gutter={10}>
                                {record.ppdOrder?.metadata.packerInfo.images?.map(image => <Col><Avatar
                                    src={
                                        <Image
                                            src={`${image}`}
                                            fallback={fallBackImage}
                                            alt={text}
                                            width="100%"
                                        />
                                    }
                                /></Col>)}
                            </Row>
                        );
                    }

                    if (key === "userInfo") {
                        //TODO: Update these.
                        return (
                            <Col>
                                <Row>Name: <>{record?.deliveryUserName}</></Row>
                                <Row>Phone: <>{record?.deliveryUserPhone}</></Row>
                            </Col>
                        );
                    }

                    if (key === "images") {
                        return (
                            <Row align="middle" gutter={10}>
                                {(record.metadata?.deliveryImages ?? []).map(image => <Col><Avatar
                                    src={
                                        <Image
                                            src={`${image}`}
                                            fallback={fallBackImage}
                                            alt={text}
                                            width="100%"
                                        />
                                    }
                                /></Col>)}
                            </Row>
                        );
                    }
                    if (key === "isDelivered") {
                        return (
                            <Row align="middle" gutter={10}>
                                {record.ppdOrder?.status}
                            </Row>
                        );
                    }

                    if (key === "actions") {
                        return (
                            <Row
                                align="middle"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Col style={{ marginRight: ".5rem" }}>
                                    <Button size="small">
                                        <Link to={`/${routes.PPD.DELIVERY_ORDERS}/${record.ppdOrderId}`}>
                                            View
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                        );
                    }
                    return <>{text}</>;
                },
            })),
        ];

        const mergedColumns = tableHeaders.map((col, i) => {
            // if (col.)
            return col;
        });

        setColumns(tableHeaders);
        setRows(data);
    }, [ppdDeliveryOrders]);

    const Paginator = () => {
        if (ppdDeliveryOrders) {
            const { pageNo, pageSize, total } = ppdDeliveryOrders;
            return (
                <StyledPagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, pageSize) => {
                        const filter = searchParams.toString();
                        setFilterCount(
                            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
                        );
                        getPpdDeliveryOrdersData(page, pageSize, filter);
                    }}
                />
            );
        }

        return <></>;
    };

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="All PPD Delivery Orders" />
                </Col>
            </Row>
            <Row>
                <AppActionStrip>
                    <div className="action-strip__item">
                        <AppFilterButton
                            active={filterState}
                            onClick={() => setFilterState(!filterState)}
                            filterCount={filterCount}
                        />
                    </div>
                </AppActionStrip>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <AppFilter expanded={filterState}>
                    <AppPpdDeliveryOrderFilter />
                </AppFilter>
            </Row>
            {rows && rows.length ? (
                <Row>
                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={rows.filter(e => e.id !== -1)}
                            scroll={{ x: "100vh", y: "65vh" }}
                            size="small"
                            pagination={false}
                            loading={status === "loading"}
                            bordered
                        />
                    </Col>
                    <Col xs={24}>
                        <Paginator />
                    </Col>
                </Row>
            ) : (
                status !== "loading" && <>No PPD delivery order to show.</>
            )}
        </>
    );

    function getHeaderWeight(key: string): number | undefined {
        const headerWeights: { [key: string]: number } = {
            "ppdorderid": 100,
            "displayorderid": 125,
            "deliverydate": 115,
            "route": 100,
            "address": 175,
            "packeruser": 200,
            "packerimages": 200,
            "userinfo": 200,
            "images": 200,
            "status": 140,
            "actions": 70
        };

        // Convert the key to lowercase for case-insensitive matching
        const lowercaseKey = key.toLowerCase();

        // Check if the key exists in the headerWeights object
        if (lowercaseKey in headerWeights) {
            return headerWeights[lowercaseKey];
        }

        // Return undefined if the key is not found
        return undefined;
    }

};

export default DeliveryOrdersPage;