import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useAppSelector } from "hooks/useAppSelector";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppCashCollectionFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [disableFields, setDisableFields] = useState(false);
    // const { cities } = useAppSelector((state) => state.adminServices);

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        form.setFieldsValue({...params, 
            // "cityId": params["cityId"] ? parseInt(params["cityId"]) : undefined,
          });
    }, [form, searchParams]);

    const onSearch = (values: Record<string, string>) => {
        let params: Record<string, string> = {};

        Object.entries(values).forEach(([key, value]) => {
            if (value) {
                if (key === "showPendingOnly" && params[key] === "0") {
                    params = { "status": "COLLECTED" };
                }
                else if (key === "date" && moment.isMoment(value)) {
                    params[key] = (value as unknown as moment.Moment).format("YYYY-MM-DD");
                }
                else {
                    params[key] = value;
                }
            }
            else { }
        });

        setSearchParams(params);
    };

    return (
        <StyledFilterContainer>
            <Form
                form={form}
                name="cash-collection-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="storeId" label="Store ID">
                            <Input placeholder="Search by Store ID" size="small" allowClear
                                disabled={disableFields}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="date" label="Date" valuePropName={'date'}
                        >
                            <DatePicker
                                disabled={disableFields}
                                format={"YYYY-MM-DD"}
                                onChange={(val) => {
                                    form.setFieldsValue({ "date": val });
                                }}
                                // disabledDate={(current) => current && current > moment(moment(), "YYYY-MM-DD")}
                                placeholder="Search by delivery date" size="small" allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="status" label="Status">
                            <Select
                                disabled={disableFields}
                                allowClear
                                showSearch
                                size="small"
                                filterOption={(input, option) => {
                                    return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                }}
                            >
                                {["REQUESTED", 'COLLECTED', "CANCELLED", "RECEIVED", "APPROVED", "REJECTED"].map((status) => (
                                    <Select.Option value={status} key={status}>
                                        {status}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        {cities && <Form.Item name="cityId" label="City" required={false} >
                            <Select placeholder="Search by City" size="small" allowClear showSearch onClear={() => {
                                form.submit();
                            }}>
                                {cities.map((e) => (
                                    <Select.Option value={e.id} key={e.id}>
                                        {e.name}, {e.state}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>}
                    </Col> */}
                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col
                        className="filter__item filter__item--submit"

                    >
                        <Form.Item>
                            <Checkbox.Group
                                onChange={(value) => {
                                    if (value.length === 1) {
                                        form.resetFields();
                                        form.setFieldsValue({ "status": "COLLECTED" });
                                        setDisableFields(true);
                                    }
                                    else {
                                        form.resetFields();
                                        setDisableFields(false);
                                    }
                                    form.submit();

                                }}
                                options={[{
                                    value: "COLLECTED",
                                    label: "Show pending only",
                                }]} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};
