import { Button, Col, Form, Input, Row, Select } from "antd";
import { useAppSelector } from "hooks/useAppSelector";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppAdminUserFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  // const { cities } = useAppSelector((state) => state.adminServices);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(searchParams));
    form.setFieldsValue({...params, 
      // "cityId": params["cityId"] ? parseInt(params["cityId"]) : undefined,
    });
  }, [form, searchParams]);

  const onSearch = (values: Record<string, string>) => {
    const params: Record<string, string> = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        params[key] = value;
      }
    });
    setSearchParams(params);
  };

  return (
    <StyledFilterContainer>
      <Form
        form={form}
        name="vendor-filter-form"
        onFinish={onSearch}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Row className="filter">
          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="firstname" label="First Name">
              <Input
                placeholder="Search by First Name"
                size="small"
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            <Form.Item name="phone" label="Phone">
              <Input placeholder="Search by phone" size="small" allowClear />
            </Form.Item>
          </Col>

          {/* <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
            {cities && <Form.Item name="cityId" label="City" required={false} >
              <Select placeholder="Search by City" size="small" allowClear showSearch onClear={() => {
                form.submit();
              }}>
                {cities.map((e) => (
                  <Select.Option value={e.id} key={e.id}>
                    {e.name}, {e.state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>}
          </Col> */}

          <Col
            xs={20}
            sm={4}
            md={2}
            lg={2}
            className="filter__item filter__item--submit"
          >
            <Form.Item>
              <Button type="primary" htmlType="submit" size="small">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFilterContainer>
  );
};
