import { Row, Col, message, Table, Pagination, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import styled from "styled-components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilterButton, AppFilter } from "components/AppFilter";
import { getAdminUsers, getCities } from "../../store/admin-slice/adminServicesSlice";
import { IAdminUsers } from "../../common/interface/IAdminUsers";
import { AppAdminUserFilter } from "../../components/AppFilter/AppAdminUserFilter/AppAdminUserFilter";
import { AiOutlineUserAdd } from "react-icons/ai";
import { variables } from "common/variables";

type PartialAdminUserType = Partial<IAdminUsers> & { key: string };
const MESSAGE_KEY = "all_admin_users";

const headerKeys: { [key: string]: string } = {
  id: "User ID",
  firstname: "First Name",
  lastname: "Last Name",
  phone: "Phone",
  // cityId: "City",
  email: "Email",
  groups: "Groups",
  blocked: "Blocked",
  actions: "Actions",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

const AdminUsersPage = () => {
  useDocumentTitle("Admin Users");
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnsType<PartialAdminUserType>>();
  const [rows, setRows] = useState<PartialAdminUserType[]>();
  const { adminUsers, cities, status, error } = useAppSelector((state) => state.adminServices);
  const [filterState, setFilterState] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);
  const navigator = useNavigate();
  const getAdminUsersData = useCallback(
    (pageNum = 1, pageSize = 25, filter = "") => {
      dispatch(getAdminUsers({ pageNo: pageNum, pageSize, filter }));
    },
    [dispatch]
  );

  // useEffect(() => {
  //   dispatch(getCities());
  // }, [])


  useEffect(() => {
    const filter = searchParams.toString();
    setFilterCount(
      Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
    );
    getAdminUsersData(1, 25, filter);
  }, [dispatch, getAdminUsersData, searchParams]);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading Admin Users", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Admin Users Loaded.",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    const adminUsersData = adminUsers.data;
    const data: PartialAdminUserType[] = adminUsersData.map((au, i) => {
      const {
        id,
        firstname,
        lastname,
        phone,
        cityId,
        email,
        groups,
        blocked,
      } = au;

      const newRow = {
        id,
        firstname,
        lastname,
        phone,
        cityId,
        email,
        groups,
        blocked,
      };
      return { ...newRow, key: `adminusers-${i}`, ellipses: true };
    });

    const tableHeaders: ColumnsType<PartialAdminUserType> = [
      ...Object.keys(headerKeys).map((key) => ({
        key,
        title: headerKeys[key],
        dataIndex: key,
        render: (text: string, record: PartialAdminUserType) => {
          if (key === "groups") {
            return (
              <>
                {
                  record["groups"]?.map(group => {
                    return <div>{group.name}</div>
                  })
                }
              </>

            )
          }

          if (key == 'blocked') {
            return <>
              {
                record["blocked"] === 0 ? "False" : "True"
              }
            </>
          }

          if (key === "cityId") {
            return <div>{cities.find(e => e.id === record.cityId)?.name}, {cities.find(e => e.id === record.cityId)?.state}</div>;
          }

          if (key === "actions") {
            return (
              <Row
                align="middle"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col>
                  <StyledActionLink to={`/admin-services/admin-users/${record.id}`}>
                    <GrView />
                  </StyledActionLink>
                </Col>
              </Row>
            );
          }

          return <>{text}</>;
        },
      })),
    ];

    setColumns(tableHeaders);
    setRows(data);
  }, [adminUsers, cities]);

  const Paginator = () => {
    if (adminUsers) {
      const { pageNo, pageSize, total } = adminUsers;
      return (
        <StyledPagination
          current={pageNo}
          pageSize={pageSize}
          total={total}
          onChange={(page, size) => {
            const filter = searchParams.toString();
            getAdminUsersData(page, size, filter)
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <Row>
        <Col>
          <AppPageHeader title="All Admin Users" />
        </Col>
      </Row>
      <Row>
        <AppActionStrip>
          <div className="action-strip__item">
            <AppFilterButton
              active={filterState}
              onClick={() => setFilterState(!filterState)}
              filterCount={filterCount}
            />
          </div>
          <Button
            icon={<AiOutlineUserAdd />}
            type={"primary"}
            onClick={() => navigator("/admin-services/add-user")}
          >
            Add User
          </Button>
        </AppActionStrip>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <AppFilter expanded={filterState}>
          <AppAdminUserFilter />
        </AppFilter>
      </Row>
      {rows && rows.length ? (
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={rows.filter(e => e.id !== -1)}
              scroll={{ x: "100vh", y: "65vh" }}
              size="small"
              pagination={false}
              loading={status === "loading"}
              bordered
            />
          </Col>
          <Col xs={24}>
            <Paginator />
          </Col>
        </Row>
      ) : (
        status !== "loading" && <>No Admin User to show.</>
      )}
    </>
  );
};

export default AdminUsersPage;
