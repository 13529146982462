import { UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Table,
    Typography,
    Upload,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { PpdInfo, TableInfo } from "common/interface/TableInfo";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getCities, getDarkStores } from "store/admin-slice/adminServicesSlice";
import { getPpdStoreTables, removePpdTableInfo, removeTableInfo, saveTableInfo } from "store/ppd/ppdSlice";

const MESSAGE_KEY = "po_info";
type PartialTableInfo = Partial<TableInfo> & { key: string };

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "select" | "text";
    record: PartialTableInfo;
    index: number;
    children: React.ReactNode;
}

const EditTablePage = () => {
    useDocumentTitle("Edit Table Page");
    const dispatch = useAppDispatch();
    const { darkStores, cities } = useAppSelector(
        (state) => state.adminServices
    );

    const { ppdTableInfo, error, status } = useAppSelector((state) => state.ppd);
    // const { poId } = useParams();
    const [viewedTableInfo, setTableInfo] = useState<PpdInfo>();
    const [tableColumns, setTableColumns] =
        useState<ColumnsType<PartialTableInfo>>();
    const [tableRows, setTableRows] = useState<any[]>();
    const [tempTableRows, setTempTableRows] = useState<any[]>();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");

    const [storeId, setStoreId] = useState<string>();
    const [cityId, setCityId] = useState<string>();

    const [isAddingItem, setIsAddingItem] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getDarkStores());
        dispatch(getCities());
        return () => {
            dispatch(removePpdTableInfo());
        };
    }, []);

    useEffect(() => {
        if (storeId != undefined) {
            cancel();
            dispatch(getPpdStoreTables({storeId: storeId, cityId: cityId,}));
        }
    }, [storeId]);

    useEffect(() => {
        // if (editingKey) {
        //     const selectedRowData = viewedPO?.poItems.find(e => e.id.toString() === editingKey.toString().split("-")[0]);
        // }
    }, [editingKey, form]);

    useEffect(() => {
        // if (vendors == undefined || vendors?.data.length == 0) {
        //     getVendorData();
        // }
    }, [])

    const isEditing = useCallback(
        (record: PartialTableInfo) => record.key === editingKey,
        [editingKey]
    );

    const edit = useCallback(
        (record: PartialTableInfo & { key: React.Key }) => {
            form.setFieldsValue({ ...record });
            setEditingKey(record.key);
        },
        [form]
    );

    const saveItem = useCallback(
        (id: string, tableInfo: TableInfo) => {
            // poItem.totalAmount = poItem.requestedQty * (poItem.billingRate ?? poItem.poRate);
            dispatch(saveTableInfo({
                ...tableInfo, storeId: storeId!,
                id: viewedTableInfo?.tableInfo?.find(e => e.tableId == tableInfo.tableId)?.id,
            },));

        },
        [dispatch, viewedTableInfo?.tableInfo],
    );

    const save = useCallback(
        async (poId: string, poItemId: string) => {
            try {
                const row = await form.validateFields();
                if (viewedTableInfo) {
                    // if (index > -1) {
                    //     saveItem(poId, {
                    //         ...row,
                    //         id: poItemId,
                    //     });
                    //     setEditingKey("");
                    // } else {
                    saveItem(poId, row);
                    setEditingKey("");
                    // }
                }

                setTempTableRows([]);
                setIsAddingItem(false);
                form.resetFields();
            } catch (errInfo) {
                console.log("Validate Failed:", errInfo);
            }
        },
        [form, saveItem, viewedTableInfo]
    );

    //   useEffect(() => {
    //     if (poId) {
    //       dispatch(getPoById(poId)).then(() => {
    //       });      
    //     }
    //   }, [poId, dispatch]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "loading table info", key: MESSAGE_KEY });
        } else if (status === "saving") {
            message.loading({ content: "saving table info", key: MESSAGE_KEY });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        if (ppdTableInfo) {
            setTableInfo(ppdTableInfo);
        }
    }, [navigate, ppdTableInfo?.storeId, ppdTableInfo,]);

    const cancel = useCallback(() => {
        setEditingKey("");
        setIsAddingItem(false);
        setTempTableRows([]);
        form.resetFields();
    }, [form]);

    useEffect(() => {
        // const thisPO = ppdTableInfo?.tableInfo.find((e) => e.tableId.toString() == editingKey.toString());

        const headerKeys: { [key: string]: string } = {
            // id: "ID",
            tableId: "Table ID",
            picker: "Picker",
            qa: "QA",
            packer: "Packer",
            isNa: "Is NA",
            actions: "Actions",
        };

        if (ppdInfo) {
            const columns = [
                ...Object.keys(headerKeys).map((key) => ({
                    key,
                    title: headerKeys[key],
                    dataIndex: key,
                    editable: key !== "actions" && true,
                    render: (text: any, record: any) => {
                        if (key === "actions") {
                            const editable = isEditing(record);
                            return editable ? (
                                <Row
                                    align="middle"
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Col style={{ marginRight: ".5rem" }}>
                                        <Typography.Link
                                            onClick={() => save(key, record.id)}
                                            disabled={status === "loading"}
                                            style={{ marginRight: 8 }}
                                        >
                                            Save
                                        </Typography.Link>
                                    </Col>
                                    <Col>
                                        <Typography.Link
                                            onClick={cancel}
                                            style={{ marginRight: 8 }}
                                            type="secondary"
                                            disabled={status === "loading"}
                                        >
                                            Cancel
                                        </Typography.Link>
                                    </Col>
                                </Row>
                            ) : (
                                <Row
                                    align="middle"
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Typography.Link
                                        onClick={() => edit(record)}
                                    >
                                        Edit
                                    </Typography.Link>
                                    <Popconfirm
                                        title="Are you sure you want to delete this item?"
                                        disabled={status === "saving"}
                                        onConfirm={() => {
                                            dispatch(removeTableInfo(record.id));
                                        }}>
                                        <Button
                                            style={{ marginLeft: "1rem" }}
                                            shape="circle"
                                            icon={<MdDeleteOutline size={18} style={{ marginTop: ".2rem" }} />}
                                        >
                                        </Button>
                                    </Popconfirm>
                                </Row>
                            );
                        }
                        if (key == "isNa") {

                            return <>{text?.toString()}</>
                        }
                        return <>{text}</>;
                    },
                })),
            ];

            const mergedColumns = columns.map((col, i) => {
                if (!col.editable) {
                    return col;
                }
                return {
                    ...col,
                    ...(i === 0 && { width: "220px" }),
                    onCell: (record: PartialTableInfo) => ({
                        record,
                        inputType: "text",
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: isEditing(record),
                    }),
                };
            });



            const rows: any[] =
                ppdTableInfo?.tableInfo
                    ?.map((poItem, idx) => ({
                        id: poItem.id,
                        tableId: poItem.tableId,
                        picker: poItem.picker,
                        packer: poItem.packer,
                        qa: poItem.qa,
                        isNa: poItem.isNa,
                        key: idx.toString(),
                    }))
                    .filter(
                        (poI) => (isAddingItem && !(poI as any).temp) || !isAddingItem
                    ) || [];
            setTableInfo(ppdTableInfo);
            setTableRows([...rows, ...(tempTableRows || [])]);
            setTableColumns(mergedColumns);
        }
    }, [
        cancel,
        edit,
        editingKey,
        form,
        isAddingItem,
        isEditing,
        // poId,
        ppdTableInfo?.tableInfo,
        save,
        tempTableRows,
        viewedTableInfo?.tableInfo,
        viewedTableInfo?.storeId,
    ]);

    const EditableCell: React.FC<EditableCellProps> = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let inputNode =
            (
                <Input
                    type="number"
                    min={0}
                    readOnly={dataIndex == "id"}
                    disabled={dataIndex == "id"}
                />
            );
        if (dataIndex == "tableId") {
            return (
                <td {...restProps}>
                    {editing ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: !["qa", "id"].includes(dataIndex),
                                    message: `Please input ${title}!`,
                                },
                                {
                                    message: 'Table ID needs to be unique',
                                    validator: (_, value) => {
                                        if ((ppdTableInfo?.tableInfo ?? []).map(e => e.tableId.toString()).includes(value)) {
                                            return Promise.reject('Table ID needs to be unique');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                },
                                {
                                    message: 'Please enter a valid integer',
                                    validator: (_, value) => {

                                        // Remove leading/trailing whitespace (optional)
                                        value = value?.toString()?.trim();

                                        // Check if empty string
                                        if (value === '' || value === undefined) {
                                            return Promise.reject('Please enter a value');
                                        }

                                        // Check for non-numeric characters using regex (optional)
                                        // This can be useful if you want to allow negative signs
                                        const nonNumericRegex = /[^-\d]/;
                                        if (nonNumericRegex.test(value)) {
                                            return Promise.reject('Please enter a number');
                                        }

                                        // Convert to number and check if integer
                                        const numberValue = parseInt(value, 10);
                                        if (isNaN(numberValue) || !Number.isInteger(numberValue)) {
                                            return Promise.reject('Please enter an integer value');
                                        }

                                        return Promise.resolve();
                                    }
                                },
                            ]}
                        >
                            {inputNode}
                        </Form.Item>
                    ) : (
                        children
                    )}
                </td>
            );
        }

        if (dataIndex == "isNa") {

            inputNode =
                (
                    <Select
                        size="small"
                        defaultValue={false}
                    >
                        {[true, false].map((status) => (
                            <Select.Option value={status} key={status.toString()}>
                                {status.toString()}
                            </Select.Option>
                        ))}
                    </Select>
                );

            return <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        initialValue={false}
                        rules={[
                            {
                                required: true,
                                message: `Please Provide ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>;
        }

        if (dataIndex === "qa") {
            return (
                <td {...restProps}>
                    {editing ? (
                        <Form.Item name={dataIndex} style={{ margin: 0 }}>
                            <Input />
                        </Form.Item>
                    ) : (
                        children
                    )}
                </td>
            );
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: !["id", "qa"].includes(dataIndex),
                                message: `Please input ${title}!`,
                            },
                            {
                                message: 'Please enter a valid integer',
                                validator: (_, value) => {
                                    // Remove leading/trailing whitespace (optional)
                                    value = value.toString().replace(/\s/g, "");

                                    // Check if empty string
                                    if (value === '') {
                                        return Promise.reject('Please enter a value');
                                    }

                                    // Check for non-numeric characters using regex (optional)
                                    const nonNumericRegex = /[^-\d]/;
                                    if (nonNumericRegex.test(value)) {
                                        return Promise.reject('Please enter a number');
                                    }

                                    // Convert to number and check if integer
                                    const numberValue = parseInt(value, 10);
                                    if (isNaN(numberValue) || !Number.isInteger(numberValue)) {
                                        return Promise.reject('Please enter an integer value');
                                    }

                                    return Promise.resolve();
                                }
                            },

                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const onAddItem = () => {
        let idx = tableRows?.length || 0;
        const key = (++idx).toString();
        setTempTableRows([...(tempTableRows || []), { key, temp: true, }]);
        setEditingKey(key);
        setIsAddingItem(true);

        setTimeout(() => {
            document
                .querySelector(".editable-last-row")
                ?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const ppdInfo = viewedTableInfo && (
        <>
            {!!(tableRows) && (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={24}>
                        <Form form={form} component={false}>
                            <Table
                                className="po-item-table"
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                bordered
                                dataSource={tableRows}
                                columns={tableColumns}
                                rowClassName={(record, index) =>
                                    index === tableRows.length - 1
                                        ? "editable-last-row editable-row"
                                        : "editable-row"
                                }
                                pagination={false}
                                scroll={{ x: "100vh", y: "65vh" }}
                                style={{ width: "100%" }}
                                size="small"
                                footer={() => (
                                    <Button
                                        type="primary"
                                        icon={<BsPlus />}
                                        onClick={onAddItem}
                                        disabled={isAddingItem}
                                    >
                                        Add Item
                                    </Button>
                                )}
                            />
                        </Form>
                    </Col>
                </Row>
            )}
        </>
    );

    return (
        <>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                <Col>
                    <AppPageHeader title="Edit Table Page" />
                </Col>
            </Row>
            <Form layout="vertical" className="mb-8">
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Form.Item 
                            label="City" 
                            name="cityId"
                            className="mb-4"
                        >
                            <Select
                                showSearch
                                placeholder="Select city"
                                className="w-full"
                                filterOption={(input, option) => {
                                    return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                }}
                                onChange={(e) => {
                                    setCityId(e);
                                    setStoreId(undefined); // Reset store selection when city changes
                                    // if (e) {
                                    //     dispatch(getPpdStoreTables({
                                    //         cityId: e,
                                    //         storeId: undefined
                                    //     }));
                                    // }
                                }}
                            >
                                {(cities ?? []).map((city) => (
                                    <Select.Option value={city.id} key={city.id}>
                                        {city.name} || {city.id}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    {cityId && (
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item 
                                label="Store ID" 
                                name="storeId"
                                className="mb-4"
                            >
                                <Select
                                    showSearch
                                    placeholder="Select store"
                                    className="w-full"
                                    value={storeId}
                                    filterOption={(input, option) => {
                                        return option?.children?.toString().toLowerCase().includes(input?.toLowerCase() ?? "") ?? false;
                                    }}
                                    onChange={(e) => {
                                        setStoreId(e);
                                        if (e && cityId) {
                                            dispatch(getPpdStoreTables({
                                                storeId: e,
                                                cityId: cityId
                                            }));
                                        }
                                    }}
                                >
                                    {(darkStores ?? [])
                                        .filter(store => store?.cityId?.toString() == cityId)
                                        .map((storeInfo) => (
                                            <Select.Option value={storeInfo.id} key={storeInfo.id}>
                                                {storeInfo.name} || {storeInfo.id}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>

            <Row>
                <Col xs={24}>{ppdInfo}</Col>
            </Row>
        </>
    );
};

export default EditTablePage;
