import { Row, Col, message, Table, Pagination, Button, Popconfirm, Switch, Dropdown, Menu } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { getCities, getDelayRequests, updateDelayStatus } from "../../store/admin-slice/adminServicesSlice";
import { variables } from "common/variables";
import { DelayRequest } from "./model/DelayRequest";
import { AppActionStrip } from "components/AppActionStrip/AppActionStrip";
import { AppFilter, AppFilterButton } from "components/AppFilter";
import { AppDelayRequestFilter } from "components/AppFilter/AppDelayRequestsFilter/AppDelayRequestFilter";

type PartialDelayType = Partial<DelayRequest> & { key: string };
const MESSAGE_KEY = "delay_requests";

const headerKeys: { [key: string]: string } = {
    // id: "User ID",
    societyName: "Society Name",
    slot: "Slot",
    delayTime: "Delay Time",
    delayReason: "Delay Reason",
    cityId: "City",
    status: "Status",
    actions: "Actions",
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledActionLink = styled(NavLink)`
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &.filterButton {
    &--active {
      border-color: ${variables.primaryColor} !important;
    }
  }
`;

const DelayRequestListingPage = () => {
    useDocumentTitle("Delay Requests");
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState<ColumnsType<PartialDelayType>>();
    const [rows, setRows] = useState<PartialDelayType[]>();
    const { delayRequests, cities, status, error } = useAppSelector((state) => state.adminServices);
    const [filterState, setFilterState] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterCount, setFilterCount] = useState(0);

    const getData = useCallback(
        (filter = "") => {
            dispatch(getDelayRequests({filter : filter,}));
        },
        [dispatch]
    );

    const updateRequest = (id: any, status: string) => {
        dispatch(updateDelayStatus({ id: id, status: status }));
    };

    useEffect(() => {
        dispatch(getCities());
    }, []);

    useEffect(() => {
        const filter = searchParams.toString();
        setFilterCount(
            Object.keys(Object.fromEntries(new URLSearchParams(filter))).length
        );
        getData(filter);
        
    }, [dispatch, getData, searchParams]);

    useEffect(() => {
        if (status === "loading") {
            message.loading({ content: "Loading", key: MESSAGE_KEY });
        } else if (status === "updating") {
            message.success({
                content: "Updating Status",
                key: MESSAGE_KEY,
                duration: 2.5,
            });
        } else if (status === "success") {
            message.success({
                content: "Status Updated",
                key: MESSAGE_KEY,
                duration: 2.5,
            });
        } else if (status === "error") {
            message.error({ content: error, key: MESSAGE_KEY, duration: 2.5, });
        }

        return () => {
            message.destroy();
        };
    }, [status, error]);

    useEffect(() => {
        
        const adminUsersData = delayRequests;
        const data: PartialDelayType[] = adminUsersData.map((au, i) => {
            const {
                id, status, delayReason, delayTime, slot, societyName,
            } = au;

            const newRow = {
                id, status, delayReason, delayTime, slot, societyName,
            };
            return { ...newRow, key: `delay-status-${i}`, ellipses: true, };
        });

        const tableHeaders: ColumnsType<PartialDelayType> = [
            ...Object.keys(headerKeys).map((key) => ({
                key,
                title: headerKeys[key],
                dataIndex: key,
                render: (text: string, record: PartialDelayType) => {

                    if (key === "cityId") {
                        if (record.cityId == null) {
                            return <></>;
                        }
                        return <div>{cities.find(e => e.id === record.cityId)?.name}, {cities.find(e => e.id === record.cityId)?.state}</div>;
                    }

                    if (key === "actions") {
                        if (record.status === "PENDING") {
                            return (
                                <Row align="middle" style={{ display: "flex", flexWrap: "wrap", gap: "1rem"}}>
                                      <Popconfirm
                                          title="Are you Sure to approve this delay?"
                                          disabled={status === "loading" || status === "saving"}
                                          onConfirm={() => updateRequest(record.id, "APPROVED")}>
                                          <StyledButton type="primary">Approve</StyledButton>
                                      </Popconfirm>
                                      <Popconfirm title="Are you sure to Reject this delay?"
                                          disabled={status === "loading" || status === "saving"}
                                          onConfirm={() => updateRequest(record.id, "CANCELLED")}>
                                          <StyledButton>Cancel</StyledButton>
                                      </Popconfirm>
                                </Row>
                              );
                          }
                    }

                    return <>{text}</>;
                },
            })),
        ];

        setColumns(tableHeaders);
        setRows(data);
    }, [delayRequests, status, cities]);

    return (
        <>
            <Row>
                <Col>
                    <AppPageHeader title="All Delay Requests" />
                </Col>
            </Row>
            <Row>
                            <AppActionStrip>
                                <div className="action-strip__item">
                                    <AppFilterButton
                                        active={filterState}
                                        onClick={() => setFilterState(!filterState)}
                                        filterCount={filterCount}
                                    />
                                </div>
                            </AppActionStrip>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                            <AppFilter expanded={filterState}>
                                <AppDelayRequestFilter />
                            </AppFilter>
                        </Row>
            {rows && rows.length ? (
                <Row>
                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={rows}
                            scroll={{ x: "100vh", y: "65vh" }}
                            size="small"
                            pagination={false}
                            loading={status === "loading"}
                            bordered
                        />
                    </Col>
                    {/* <Col xs={24}>
                        <Paginator />
                    </Col> */}
                </Row>
            ) : (
                status !== "loading" && <>No Delay Requests to show.</>
            )}
        </>
    );
};

export default DelayRequestListingPage;
