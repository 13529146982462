import {Button, Col, DatePicker, Form, Input, Row, Select} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {useAppSelector} from "../../../hooks/useAppSelector";

const StyledFilterContainer = styled.div`
  &,
  & .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__item {
      margin: 0.5rem;

      & .ant-form-item {
        margin: 0;
      }

      &--submit {
        align-self: flex-end;
      }
    }
  }
`;

export const AppPpdDeliveryOrderFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const { darkStores } = useAppSelector((state) => state.adminServices);
    const { cities } = useAppSelector((state) => state.adminServices);
    const [isDateRequired, setIsDateRequired] = useState(false);

    useEffect(() => {

        const params = Object.fromEntries(new URLSearchParams(searchParams));
        const formValues = {
            ...params,
            fromDate: params.fromDate ? moment(params.fromDate, "YYYY-MM-DD") : undefined,
            toDate: params.toDate ? moment(params.toDate, "YYYY-MM-DD") : undefined,
            "storeId": params["storeId"] ? parseInt(params["storeId"]) : undefined,
            "cityId": params["cityId"] ? parseInt(params["cityId"]) : undefined,
        };

        form.setFieldsValue(formValues); 
        
        // Set initial required state based on URL params
        setIsDateRequired(!!(params.fromDate || params.toDate));
    }, [form, searchParams]);

    const onSearch = async (values: Record<string, string>) => {
        try {
            // Validate form before proceeding
            await form.validateFields();
            
            const params: Record<string, string> = {};
            Object.entries(values).forEach(([key, value]) => {
                if (value) {
                    if (key === "toDate" || key === "fromDate") {
                        params[key] = moment(value).format("yyyy-MM-DD");
                    } else {
                        params[key] = value;
                    }
                }
            });
            console.log(values);
            
            setSearchParams(params);
        } catch (error) {
            // Form validation failed
            console.error('Validation failed:', error);
        }
    };

    const handleDateChange = (value: moment.Moment | null, field: 'fromDate' | 'toDate') => {
        const otherField = field === 'fromDate' ? 'toDate' : 'fromDate';
        const otherValue = form.getFieldValue(otherField);

        // Update the form values
        form.setFieldsValue({ [field]: value });

        // Set required state if either date is selected
        setIsDateRequired(!!(value || otherValue));
    };

    const disabledFromDate = (current: moment.Moment | null) => {
        if (!current) return false;
        if (current > moment()) return true;

        return false;
    };

    const disabledToDate = (current: moment.Moment | null) => {
        if (!current) return false;        
        if (current > moment()) return true;
        return false;
    };

    return (
        <StyledFilterContainer>
            <Form
                form={form} 
                name="ppd-order-filter-form"
                onFinish={onSearch}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Row className="filter">
                    <Col className="filter__item">
                        <Form.Item 
                            name="fromDate" 
                            label="From Date" 
                            valuePropName='date'
                            rules={[
                                {
                                    required: isDateRequired,
                                    message: 'Please select From Date'
                                }
                            ]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                onChange={(val) => handleDateChange(val, 'fromDate')}
                                disabledDate={disabledFromDate}
                                placeholder="Search by from date"
                                size="small"
                                allowClear
                            />
                        </Form.Item>
                    </Col>

                    <Col className="filter__item">
                        <Form.Item 
                            name="toDate" 
                            label="To Date" 
                            valuePropName='date'
                            rules={[
                                {
                                    required: isDateRequired,
                                    message: 'Please select To Date'
                                }
                            ]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                onChange={(val) => handleDateChange(val, 'toDate')}
                                disabledDate={disabledToDate}
                                placeholder="Search by to date"
                                size="small"
                                allowClear
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        {darkStores && <Form.Item name="storeId" label="Store ID" required={false} >
                            <Select placeholder="Search by Store ID" size="small" allowClear showSearch onClear={() => {
                                form.submit();
                            }}>
                                {(darkStores).map((e) => (
                                    <Select.Option value={e.id} key={e.id}>
                                        {e.name} || {e.id}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>}
                    </Col>
                    
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        {cities && <Form.Item name="cityId" label="City" required={false} >
                            <Select placeholder="Search by City" size="small" allowClear showSearch onClear={() => {
                                form.submit();
                            }}>
                                {cities.map((e) => (
                                    <Select.Option value={e.id} key={e.id}>
                                        {e.name}, {e.state}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>}
                    </Col>
                    
                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="displayOrderId" label="Order ID">
                            <Input placeholder="Search by Order ID" size="small" allowClear />
                        </Form.Item>
                    </Col>

                    <Col xs={20} sm={4} md={5} lg={4} className="filter__item">
                        <Form.Item name="customerPhone" label="Customer Phone">
                            <Input placeholder="Search by Customer Phone" size="small" allowClear />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={20}
                        sm={4}
                        md={2}
                        lg={2}
                        className="filter__item filter__item--submit"
                    >
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="small">
                                Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledFilterContainer>
    );
};