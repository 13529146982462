import { DownOutlined, InfoCircleOutlined, VideoCameraFilled, PlusOutlined, UpOutlined, WarningOutlined } from "@ant-design/icons";
import { MdDeleteForever } from "react-icons/md";

import {
  Row,
  Col,
  Button,
  message,
  Form,
  Input,
  Select,
  Card,
  Grid,
  InputNumber,
  Switch,
  Tooltip,
} from "antd";
import { AppPageHeader } from "components/AppPageHeader";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useDocumentTitle } from "hooks/usePageTitle";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewSociety,
  getCities,
  getClusterListing,
  getDarkStores,
  getPpdSlots,
  // getRightAwayStores,
  updateSociety,
} from "store/admin-slice/adminServicesSlice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DndItem from "./component/DnDItem";
import { ClusterInfo } from "common/interface/Society";

const { useBreakpoint } = Grid;

const MESSAGE_KEY = "CREATE_SOCIETY";
export const CreateNewSocietyPage = () => {
  useDocumentTitle("Create New Society");
  const { status, error, darkStores,  societies, clusters, ppdSlots, cities } =
    useAppSelector((state) => state.adminServices);
  const [towers, setTowers] = useState<String[]>([]);
  const [clustersList, setClustersList] = useState<ClusterInfo[]>([]);
  const [formWidth, setFormWidth] = useState("100%");
  const [form] = Form.useForm();
  const [filteredStores, setFilteredStores] = useState(darkStores);
  // const [filteredRightAwayStores, setFilteredRightAwayStores] = useState(rightAwayStores);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const screens = useBreakpoint();
  const sampleAreaPolygon = "POLYGON ((77.058178 28.443964, 77.066364 28.452456, 77.069405 28.450173, 77.065957 28.446555, 77.065055 28.447231, 77.060305 28.44224, 77.063182 28.439989, 77.060367 28.437085, 77.057915 28.438874, 77.059033 28.439945, 77.058316 28.440443, 77.060117 28.442335, 77.058178 28.443964))";
  const isContactDelivery = Form.useWatch('isContactDelivery', form);

  useEffect(() => {
    if (id != null) {
      let societyData = societies.find((e) => e.id == id);
      if (societyData) {
        setTowers(societyData.tower.map((e) => e.towerName));
        setClustersList(
          clusters,
        );
        form.setFieldsValue({
          ...societyData,
          houseHolds: societyData?.metadata?.houseHolds,
          isPrepaid: societyData?.metadata?.isPrepaid,
          validSlots: societyData?.metadata?.validSlots ?? [],
          active: societyData.active,
        });
      } else {
        navigate("/admin-services/society");
      }
    }
  }, []);

  useEffect(() => {
    if (status === "loading") {
      message.loading({ content: "Loading", key: MESSAGE_KEY });
    } else if (status === "success") {
      message.success({
        content: "Society Added!",
        key: MESSAGE_KEY,
      });
      form.resetFields();
      navigate(-1);
    } else if (status === "saving") {
      message.loading({
        content: "Saving",
        key: MESSAGE_KEY,
      });
    } else if (status === "error") {
      message.error({ content: error, key: MESSAGE_KEY });
    }

    return () => {
      message.destroy();
    };
  }, [status, error]);

  useEffect(() => {
    dispatch(getDarkStores());
    // dispatch(getRightAwayStores());
    dispatch(getPpdSlots());
    dispatch(getCities());

    dispatch(getClusterListing());
    return () => {
      message.destroy();
    };
  }, []);

  const onSave = async () => {
    try {
      await form.validateFields();
      let data = form.getFieldsValue();
      form.setFieldsValue({
        "city": cities.find(e => e.id == data["cityId"])?.name,
        "state": cities.find(e => e.id == data["cityId"])?.state,
      });
      if (data.metadata?.validSlots) {
        data.metadata.validSlots = data.metadata.validSlots.filter(
          (slot: any) => slot != null
        );
      }
      if (id != null) {
        data["id"] = id;

        if (data["tower"]) {
          data["tower"].forEach(
            (e: any, index: number) => (e["deliverySequence"] = index + 1)
          );
        }
        data["active"] = form.getFieldValue("active");
        await dispatch(updateSociety(data));
      } else {
        let data = form.getFieldsValue();
        data["active"] = 1;
        await dispatch(addNewSociety(data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onCancelSave = () => {
    navigate(-1);
  };

  const handleFormValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "cityId") {
      // Clear existing store selections when city changes
      form.setFieldsValue({
        storeId: [],
      });
      

      // Filter stores based on selected city
      const selectedCityId = changedValues[fieldName];
      const cityFilteredStores = darkStores.filter(store => store.cityId === selectedCityId);
      // const cityFilteredRightAwayStores = rightAwayStores.filter(store => store.cityId === selectedCityId);
      setFilteredStores(cityFilteredStores);
      
      // setFilteredRightAwayStores(cityFilteredRightAwayStores);
    }

    if (fieldName === "storeId") {
      const value = changedValues[fieldName];
      form.setFieldsValue({ clusterId: undefined });
      setClustersList(clusters);
      const selectedStoreId = changedValues[fieldName];
    // Filter clusters based on selected store IDs
    const updatedFilteredClusters = clusters.filter(cluster => selectedStoreId == (cluster.storeId));
    setClustersList(updatedFilteredClusters);
    }
  };

  useEffect(() => {
    if (screens.lg) {
      setFormWidth("70%");
    } else {
      setFormWidth("100%");
    }
  }, [screens]);

  const _validateStores = (_: any, value: any) => {
    const storeIds = form.getFieldValue('storeIds') || [];
    const rightAwayStoreIds = form.getFieldValue('rightAwayStoreIds') || [];

    if (storeIds.length === 0 && rightAwayStoreIds.length === 0) {
      return Promise.reject('At least one store type must be selected');
    }
    return Promise.resolve();
  };

  const copyToClipboard = () => {
    if (isContactDelivery === 1) {
      navigator.clipboard.writeText(sampleAreaPolygon).then(() => {
        message.success({
          content: 'Sample text copied to clipboard!',
          key: MESSAGE_KEY,
          duration: 1.5,
        });
      }).catch((err) => {
        message.error('Failed to copy text: ', err);
      });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Row>
        <Col xs={24}>
          <AppPageHeader
            title={id != null ? "Update Society" : "Create New Society"}
          />
        </Col>
      </Row>
      <Form
        form={form}
        name="vendor-filter-form"
        layout="vertical"
        style={{ width: formWidth }}
        onValuesChange={handleFormValuesChange}
      >
        <Col xs={24}>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Name" size="small" allowClear required />
              </Form.Item>
            </Col>

            <Col xs={24} md={2}></Col>

            <Col xs={24} md={5}>
              <Form.Item
                name={["metadata", "houseHolds"]}
                label="House Holds"
                rules={[]}
              >
                <Input placeholder="House Holds" size="small" type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={14}>
              <Form.Item name={["metadata", "validSlots"]} label="Valid Slots">
                <Select
                  mode="multiple"
                  placeholder="Select valid slots"
                  style={{ width: "100%" }}
                  defaultValue={[]}
                >
                  {ppdSlots.map((ppdSlot, i) => (
                    <Select.Option key={ppdSlot.id} value={ppdSlot.id}>
                      {ppdSlot.slot}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={2}></Col>

            <Col xs={24} md={8}>
              <Form.Item
                name={["metadata", "isPrepaid"]}
                label="Is Prepaid"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name={["isContactDelivery"]}
                label="Is Contact Delivery"
                rules={[{ required: true }]}
              >
                <Select placeholder="Select contact delivery status">
                  <Select.Option value={0}>No</Select.Option>
                  <Select.Option value={1}>Yes</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* <Col xs={24} md={2}></Col>

            <Col xs={24} md={5}>
              <Form.Item
                name={["metadata", "dockNumber"]}
                label="Dock Number"
                rules={[{ required: true }]}
              >
                <Input placeholder="Dock Number" size="small" type="number" />
              </Form.Item>
            </Col> */}
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={19}>
              <Form.Item
                name="areaPolygon"
                label="Area Polygon"
                rules={[
                  {
                    required: isContactDelivery === 1,
                    message: 'Area Polygon is required for contact delivery',
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter area polygon coordinates"
                  size="small"
                  allowClear
                  disabled={isContactDelivery !== 1}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} style={{ paddingTop: '30px', paddingLeft: '10px' }}>
              <Tooltip title={isContactDelivery === 1 ? `Sample input: ${sampleAreaPolygon}` : "Enable Contact Delivery to update"}>
                {isContactDelivery === 1 ? (
                  <InfoCircleOutlined style={{ fontSize: '16px', color: '#1890ff' }} onClick={copyToClipboard} />
                ) : (
                  <WarningOutlined style={{ fontSize: '16px', color: '#faad14' }} />
                )}
              </Tooltip>
              <VideoCameraFilled style={{ fontSize: '16px', color: 'red' }} onClick={() => window.open('https://youtu.be/Eermg5gJ5ns', '_blank')} />
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col md={6} xs={24}>
              {cities && <Form.Item
                name="cityId"
                label="City"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select City"
                  style={{ width: "100%" }}
                >
                  {cities.map((city) => (
                    <Select.Option key={city.id} value={city.id} onChange={() => {
                      console.log("Here");
                      
                      form.setFieldsValue({
                        "city": city.name,
                        "state": city.state,
                      });
                    }}>
                      {city.name}, {city.state}
                    </Select.Option>
                  ))}
                </Select>
                {/* <Input placeholder="City" size="small" allowClear required /> */}
              </Form.Item>}
            </Col>
            <Form.Item
              name="city"

            ></Form.Item>
            <Form.Item
              name="state"

            ></Form.Item>

            <Col xs={24} md={1}></Col>

            <Col md={5} xs={24}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Pincode"
                  size="small"
                  maxLength={6}
                  allowClear
                  type="number"
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={6}>
              <Form.Item
                name="storeId"
                label="Store"
                rules={[]}
              >
                <Select
                  allowClear
                  showSearch
                  size="small"
                  filterOption={(input, option) => {
                    return (
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input?.toLowerCase() ?? "") ?? false
                    );
                  }}
                >
                  {filteredStores.map((status) => (
                    <Select.Option value={status.id} key={status.id}>
                      {status.name} || {status.id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={1}></Col>
            <Col xs={24} md={11}>
              <Form.Item
                name="rightAwayStoreIds"
                label="Right Away Stores"
                rules={[{ validator: _validateStores }]}
              >
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  size="small"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      .toLowerCase()
                      .includes(input?.toLowerCase() ?? "") ?? false;
                  }}
                >
                  {filteredRightAwayStores.map((store) => (
                    <Select.Option value={store.id} key={store.id}>
                      {store.name} || {store.id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={6}>
              <Form.Item
                name="clusterId"
                label="Cluster"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  size="small"
                  filterOption={(input, option) => {
                    return (
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input?.toLowerCase() ?? "") ?? false
                    );
                  }}
                >
                  {clustersList.map((status) => (
                    <Select.Option value={status.id} key={status.id}>
                      {status.name} || {status.id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={1}></Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="deliverySeq"
                label="Delivery Seq"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="Delivery Sequence"
                  size="small"
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24} md={8}>
              <Form.Item
                name="latitude"
                label="Latitide"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Latitude"
                  size="small"
                  type="number"
                  allowClear
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}></Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Longitude"
                  size="small"
                  type="number"
                  allowClear
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12}>
          <Card
            title={`Towers List`}
            key={`towers list`}
            bodyStyle={{ padding: "1.5rem 1.5rem 0 1rem" }}
          >
            <>{"Tower Name"}</>
            <Form.Item
              name="checkTowers"
              // label="Tower"
              className="requiredField"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, __) {
                    if (
                      getFieldValue("tower") &&
                      getFieldValue("tower").length
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please add tower.");
                  },
                }),
              ]}
            >
              <Form.List
                name="tower"
                rules={[
                  {
                    validator: (_, value) => {
                      if (Array.from(value ?? []).length == 0) {
                        return Promise.reject("Table ID needs to be unique");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove, move }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <DndItem
                        key={index}
                        index={index}
                        moveItem={(a: any, b: any) => {
                          move(a, b);
                          let temp = form.getFieldValue("tower");
                          temp[a]["deliverySequence"] = a + 1;
                          temp[b]["deliverySequence"] = b + 1;
                          form.setFieldsValue({ tower: temp });
                        }}
                        children={
                          <Card
                            style={{ margin: "0 0 0 0" }}
                            bodyStyle={{ padding: "1rem 1rem 0 .5rem" }}
                          >
                            <Col key={index}>
                              <Row align="middle" justify="center">
                                <Col style={{ width: "10%" }}>
                                  {index + 1}
                                  {"."}
                                </Col>
                                <Col style={{ width: "90%" }}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "towerName"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Name Required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Name"
                                      required
                                      suffix={
                                        <MdDeleteForever
                                          style={{ cursor: "pointer" }}
                                          size={22}
                                          color="#cc0000"
                                          onClick={() => remove(name)}
                                        />
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                {
                                  <Col style={{ width: "20%" }}>
                                    <Button.Group>
                                      <Button
                                        size="small"
                                        icon={<DownOutlined />}
                                        onClick={() => move(index, index + 1)}
                                        disabled={index === fields.length - 1}
                                      />
                                      <Button
                                        size="small"
                                        icon={<UpOutlined />}
                                        onClick={() => move(index, index - 1)}
                                        disabled={index == 0}
                                      />
                                    </Button.Group>
                                  </Col>
                                }
                              </Row>

                              <Form.Item
                                {...restField}
                                name={[name, "deliverySequence"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Sequence Required",
                                  },
                                ]}
                                noStyle
                              />
                            </Col>
                          </Card>
                        }
                      />
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            deliverySequence:
                              (form.getFieldValue("tower") ?? []).length + 1,
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Tower
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Card>
        </Col>
      </Form>
      <Row>
        <Col>
          <Button
            type="primary"
            onClick={onSave}
            loading={status === "loading"}
            disabled={status === "loading"}
            style={{ marginTop: "1rem" }}
          >
            {id != null ? "Update Society" : "Add Society"}
          </Button>
        </Col>
        <Col
          style={{
            justifyContent: "left",
            marginLeft: "20px",
            marginTop: "1rem",
          }}
        >
          <Button onClick={onCancelSave} disabled={status === "loading"}>
            Cancel
          </Button>
        </Col>
      </Row>
    </DndProvider>
  );
};
